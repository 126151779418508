
.ps-submenu-content{
    background-color: rgba(50, 50, 50, 1) !important;
}

.ps-submenu-content a.menu-button{
    color: rgba(175, 175, 175, 0.67)
}

a.ps-menu-button.active{
    background-color: rgb(26, 115, 232);
    color: white;
}